/* css autodemo */


:root {
    --primaryTextColor: #ffffff;
    --panelContentBorder: 1px solid #c8c8c8;
    --emcColorPrimary: #1f4d67;
    --emcColorPrimaryDark: #122b3a;
    --emcColorPrimaryLight: #367193;
    --emcColorSecondary: #d8bb82;
}

/* holograma en instrucciones */
.holograma {
    background-color: #47dbe2;
    box-shadow: inset 0px 0px 100px 0px rgba(90, 250, 255, 1);
    background: repeating-linear-gradient(15deg, #acf8ff, #acf8ff 10px, #baf9ff 10px, #baf9ff 20px);
    border-radius: 35px !important;
}

/* texto en secondary color (ej. MATILDA en login, registro) */
.text-secondary {
    color: var(--emcColorSecondary) !important;
    text-shadow: 0px 0px 6px rgba(115, 95, 68, 0.7);
}


/* bg gradient inferior en login y registro */
.login.login-with-news-feed .news-feed .news-caption,
.register.register-with-news-feed .news-feed .news-caption {
    background: linear-gradient(to bottom,rgba(0,0,0,0) 0,#122c3b 100%);
}


/* box resumen en resultados */
.box-resumen {
    background-color: #b0c2cd;
}
.box-resumen textarea, .box-resumen input {
    font-size: 1rem;
    font-weight: 900;
}



/* bg color transp panels headers */
.bg-blue-emc-transparent-3 {
    background-color: rgba(118,190,231,.6) !important;
}



/* bg color header */
.header.navbar-inverse { background: var(--emcColorPrimary) }

/* bg color sidebar */
.sidebar { background: #293b47; }
    /* sidebar user cover */
    .sidebar .nav > li.nav-profile .cover {
        background: url(images/cover-sidebar-user-emc.jpg);
        background-repeat: no-repeat;
        background-size: cover;
    }



/* bg en iconos headers panels */
.bg-gradient-blue {
    background: var(--emcColorPrimary) !important;
    background: -moz-linear-gradient(to bottom, var(--emcColorPrimaryLight) 0,var(--emcColorPrimaryDark) 100%) !important;
    background: -webkit-linear-gradient(to bottom,var(--emcColorPrimaryLight) 0,var(--emcColorPrimaryDark) 100%) !important;
    background: linear-gradient(to bottom,var(--emcColorPrimaryLight) 0,var(--emcColorPrimaryDark) 100%) !important
}

.text-blue-darker {
    color: var(--emcColorPrimary) !important;
}



/* borde en alert boxes */
.alert {
    border-width:1px;
}


/* badges dorados en sidebar */
.sidebar .nav > li.active > a i,
.sidebar .sub-menu > li.active:after {
    color: var(--emcColorSecondary);
    border-color: var(--emcColorSecondary);
}


/* btn primary */
.btn-primary {
    color: #fff;
    background-color: var(--emcColorPrimaryLight);
    border-color: var(--emcColorPrimaryLight);
}
    .btn-primary.hover, .btn-primary:hover {
        color: var(--emcColorSecondary);
        background-color: #1f4d67;
        border-color: #122b3a;
    }
    .btn-primary.focus, .btn-primary:focus {
        color: #e1eeeb;
        background-color: var(--emcColorPrimaryDark);
        border-color: #007979;
        box-shadow: 0 0 0 0 rgba(38,184,184,.5)
    }
    .btn-primary.disabled, .btn-primary:disabled {
        color: #fff;
        background-color: #00acac;
        border-color: #00acac
    }
    .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
        color: var(--emcColorSecondary);
        background-color: #1f4d67;
        border-color: #006c6c
    }
        .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
            box-shadow: 0 0 0 0 rgba(38,184,184,0);
        }



